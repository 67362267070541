import * as React from "react"
import SEO from "components/seo"
import { ClientsT, PageContextT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import "react-tiger-transition/styles/main.min.css"
import { getIsSSR } from "utilities/ssr"
import Container from "components/atoms/Container"
import LoadingContainer from "components/atoms/LoadingContainer"
import { useTranslation } from "react-i18next"

type Props = {
  pageContext: PageContextT
  clients: ClientsT
}

const AboutUsContainerLazy = React.lazy(
  () => import("components/organisms/AboutUs/AboutUsContainer")
)

const AboutUs: React.ComponentType<Props> = (props: Props) => {
  const [transition, setTransition] = React.useState("transition-right")
  const [activeIndex, setActiveIndex] = React.useState(-1)
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      l3,
      aboutUsItems,
      uploadedFiles,
      configurations,
      mobileHeaderLinks,
      organizationStructure,
    },
  } = props

  const resetGrid = () => {
    setTransition("zoom")
    setActiveIndex(-1)
  }
  function handleTabChange(newValue: number, transition: string) {
    if (activeIndex === -1) {
      setTransition("zoom")
    } else {
      setTransition(transition)
    }

    setActiveIndex(newValue)
  }

  const isSSR = getIsSSR()
  const { i18n } = useTranslation()

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      l3={l3}
      activeIndex={activeIndex}
      onReset={resetGrid}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={[]}
      localizedPage={{
        id: 1,
        title: "About us",
        url: i18n.language === "en" ? "/o-spolecnosti" : "/en/about-us",
        isHomepage: false,
        order: 1,
        page: null,
        section: null,
        parent: null,
      }}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
      />

      {!hasMounted && (
        <div className={"bg-gray-100"}>
          <Container>
            <LoadingContainer />
          </Container>
        </div>
      )}

      {!isSSR && hasMounted && (
        <div className={"bg-gray-100"}>
          <React.Suspense fallback={<LoadingContainer />}>
            <AboutUsContainerLazy
              aboutUsItems={aboutUsItems}
              activeIndex={activeIndex}
              resetGrid={resetGrid}
              handleTabChange={handleTabChange}
              transition={transition}
              uploadedFiles={uploadedFiles}
              organizationStructure={organizationStructure}
            />
          </React.Suspense>
        </div>
      )}
    </Layout>
  )
}

export default AboutUs
