import React from "react"
import styled from "styled-components"
import { mediaQueries } from "styles/variables"
import Loader from "./Loader"

type PropsT = {}

const StyledLoadingContainer = styled.div`
  width: 100%;
  height: 740px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQueries.lgMin} {
    height: 600px;
  }
  ${mediaQueries.smMax} {
    height: 800px;
  }
  ${mediaQueries.xlMin} {
    height: 740px;
  }
`

const LoadingContainer = ({}: PropsT) => {
  return (
    <StyledLoadingContainer className={"bg-gray-100"}>
      <Loader />
    </StyledLoadingContainer>
  )
}

export default LoadingContainer
