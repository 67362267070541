import React from "react"
import styled from "styled-components"
import { colors } from "styles/variables"
import classNames from "classnames"

type PropsT = {
  marginBottom?: boolean
}

const LoaderWrapper = styled.div`
  border-top-color: ${colors.primary};
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = ({ marginBottom = true }: PropsT) => {
  return (
    <LoaderWrapper
      className={classNames(
        "ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12",
        {
          "mb-4": marginBottom,
        }
      )}
    ></LoaderWrapper>
  )
}

export default Loader
